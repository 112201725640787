import FadeInOutEffect from "../../shared/fadeInOutEffect/FadeInOutEffect";
import "./InformationImg.css";

const InformationImg = () => {
  return (
    <div className="information-img-wrapper">
      <div className="information-img-text-wrapper">
        <FadeInOutEffect>
          <div className="information-img-first-title">Ancestral</div>
          <div className="information-img-second-title">Recipes</div>
        </FadeInOutEffect>
      </div>
    </div>
  );
};

export default InformationImg;
