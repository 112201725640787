import FadeInOutEffect from "../shared/fadeInOutEffect/FadeInOutEffect";
import "./HeroImage.css";

const HeroImage = ({ screenHeight }) => {
  const currentYear = new Date().getFullYear();

  const heroTextList = ["Spirit Driven", "Fresh", "Vegan", "Ancestral", "Soul"];

  return (
    <div className="hero-img-wrapper">
      <FadeInOutEffect>
        <h1 className="hero-title">Drop Squad Kitchen</h1>
        <div className="hero-text">
          {heroTextList.map((data, index) => {
            return <div key={`${data}${index}`}>• {data}</div>;
          })}
        </div>
        <div className="hero-text-year">•{currentYear}•</div>
      </FadeInOutEffect>
    </div>
  );
};

export default HeroImage;
