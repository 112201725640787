import "./MainFooter.css";

const MainFooter = () => {
  return (
    <div className="footer-wrapper">
      <div className="contact-wrapper">
        <div>+1 (302) 984-2773</div>
        <a
          href="mailto:dropsquadkitchen@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          <p className="footer-underline-anchor">Email us</p>
        </a>
      </div>
      <div className="footer-information-wrapper">
        <div className="footer-single-info-wrapper">
          <div className="footer-info-title">Contact info</div>
          <div className="footer-info">928 Justison Street</div>
          <div className="footer-info">Wilmington, DE 19801</div>
        </div>
        <div className="footer-single-info-wrapper">
          <div className="footer-info-title">Additional</div>
          <div className="footer-info">
            <a
              href="https://order.online/store/drop-squad-kitchen-wilmington-325474/?hideModal=true&pickup=true"
              target="_blank"
              rel="noreferrer"
            >
              <p className="footer-underline-anchor-info">Order Online</p>
            </a>
          </div>
          <div className="footer-info">
            <a
              href="https://demos.pixelgrade.com/rosa2/contact/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="footer-underline-anchor-info">Get Directions</p>
            </a>
          </div>
        </div>
        <div className="footer-single-info-wrapper">
          <div className="footer-info-title">Opening Hours</div>
          <div className="footer-info">Sun - Thurs</div>
          <div className="footer-info">11:11 AM - 8:00 PM</div>
          <div className="footer-info">Fri - Sat</div>
          <div className="footer-info">11:11 AM - 9:00 PM</div>
          <div style={{ margin: "30px 0" }}>
            Closed: Thanksgiving & Christmas
          </div>
        </div>
        <div className="footer-single-info-wrapper">
          <div className="footer-info-title">Follow us</div>
          <div className="footer-info">
            <a
              href="https://www.facebook.com/dropsquadkitchen/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="footer-underline-anchor-info">Facebook</p>
            </a>
          </div>
          <div className="footer-info">
            <a
              href="https://www.instagram.com/dropsquadkitchen/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <p className="footer-underline-anchor-info">Instagram</p>
            </a>
          </div>
          <div className="footer-info">
            <a
              href="https://www.youtube.com/channel/UCxeK1PV8gQjeUjeggN5RSYg"
              target="_blank"
              rel="noreferrer"
            >
              <p className="footer-underline-anchor-info">YouTube</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
