import FadeInOutEffect from "../../shared/fadeInOutEffect/FadeInOutEffect";
import "./SecondInformationImg.css";

const SecondInformationImg = () => {
  return (
    <div className="second-information-img-wrapper">
      <div className="second-information-img-text-wrapper">
        <FadeInOutEffect>
          <div className="second-information-img-first-title">Spirit</div>
          <div className="second-information-img-second-title">Driven</div>
        </FadeInOutEffect>
      </div>
    </div>
  );
};

export default SecondInformationImg;
