import { useState } from "react";

import RoundBtn from "../shared/roundBtn/RoundBtn";
import CloseIcon from "@mui/icons-material/Close";

import "./OrderModal.css";

const OrderModal = (params) => {
  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => setOpenModal(false);

  return (
    <div
      className={`${openModal ? "modal-bg-wrapper" : ""}`}
      onClick={handleClose}
    >
      {openModal && (
        <div className="modal-wrapper">
          <div className="modal-close-btn">
            <CloseIcon
              fontSize="large"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>
          <div className="modal-info-wrapper">
            <div className="modal-title-text">Order Now</div>
            <div className="modal-info-text">
              Track and manage your order online.
            </div>

            <div className="modal-btn-wrapper">
              <RoundBtn
                text="Order Online"
                url="https://order.online/store/drop-squad-kitchen-wilmington-325474/?hideModal=true&pickup=true"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderModal;
