import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import "./ScheduleNavbar.css";

const ScheduleNavbar = () => {
  const [showScheduleNavbar, setShowScheduleNavbar] = useState(true);
  return (
    <>
      {showScheduleNavbar && (
        <div>
          <div className="schedule-navbar-wrapper">
            <div className="schedule-navbar-text">
              OPEN MONDAY - SATURDAY 12:12 PM - 8:00 PM. CLOSED SUNDAYS
            </div>
            <div className="schedule-navbar-btn">
              <CloseIcon
                fontSize="inherit"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowScheduleNavbar(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduleNavbar;
