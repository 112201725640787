import RoundBtn from "../shared/roundBtn/RoundBtn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./MainOrderBtn.css";

const MainOrderBtn = ({ scrollPosition, currentScreenHeight }) => {
  const scrollHandler = () => {
    window.scroll({ top: currentScreenHeight, behavior: "smooth" });
  };

  return (
    <div className="main-order-btn-wrapper">
      {scrollPosition <= currentScreenHeight * 0.1 && (
        <div className="scroll-down-wrapper" onClick={scrollHandler}>
          <svg
            width="160"
            height="50"
            viewBox="0 0 160 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 50C55 50 45 0 80 0C115 0 105 50 160 50H0Z"
              fill="white"
            ></path>
          </svg>
          <ExpandMoreIcon fontSize="large" className="scroll-down-symbol" />
        </div>
      )}
      <RoundBtn
        text="Order Online"
        url="https://order.online/store/drop-squad-kitchen-wilmington-325474/?hideModal=true&pickup=true"
      />
    </div>
  );
};

export default MainOrderBtn;
