import React, { useState, useEffect } from "react";

import HeroImage from "../components/heroImg/HeroImage";
import MainOrderBtn from "../components/mainOrderBtn/MainOrderBtn";
import getHeight from "../utils/getHeight.js";
import InformationContent from "../components/informationContent/InformationContent";
import InformationImg from "../components/informationImages/informationImg/InformationImg";

import SecondInformationImg from "../components/informationImages/secondInformationImg/SecondInformationImg";
import MainFooter from "../components/mainFooter/MainFooter";
import OrderModal from "../components/orderModal/OrderModal";
import "./MainView.css";

const MainView = () => {
  // GET SCREEN HEIGHT POSITION
  const [scrollPosition, setScrollPosition] = useState(0);
  const currentHeight = getHeight("100vh");

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <OrderModal />
      <HeroImage />
      <MainOrderBtn
        scrollPosition={scrollPosition}
        currentScreenHeight={currentHeight}
      />
      <InformationContent
        firstTitle="Fresh"
        secondTitle="Vegan Soul"
        infoText="We create boutique style, from scratch, plant based food using thoughtfully curated ingredients from the earth. Every one of our recipes reflect a multicultural influence or a story."
        imgNumber="1"
      />
      <InformationImg />
      <InformationContent
        firstTitle="Check out"
        secondTitle="Today’s Menu"
        infoText="When you order from us imagine telling your mama what you want to eat. We make everything to order, literally. Your mac is baked on the spot, Hot Dog grilled on Demand. If we have to store it we don’t want to serve it."
        imgNumber="2"
      >
        <a
          href="https://order.online/store/drop-squad-kitchen-wilmington-325474/?hideModal=true&pickup=true"
          target="_blank"
          rel="noreferrer"
        >
          <p className="menu-btn">View the Food Menu</p>
        </a>
      </InformationContent>
      <SecondInformationImg />
      <InformationContent
        firstTitle="Experience"
        secondTitle="Drop Squad"
        infoText="Although we only have indoor seating for 15, we offer an intimate culturally rich counter service dining experience that feels like someone’s eat in kitchen. For those of you that can appreciate Mother Nature, We also have patio seating right on the river."
        imgNumber="3"
      />
      <div className="third-info-btn-wrapper">
        <a
          href="https://www.youtube.com/channel/UCxeK1PV8gQjeUjeggN5RSYg/"
          target="_blank"
          rel="noreferrer"
        >
          <button className="third-info-btn third-info-btn-slide">
            View the Experience
          </button>
        </a>
      </div>

      <MainFooter />
    </div>
  );
};

export default MainView;
