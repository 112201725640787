import { Routes, Route, Navigate } from "react-router-dom";

import MainView from "./views/MainView";
import ScheduleNavbar from "./components/scheduleNavbar/ScheduleNavbar.js";

function App() {
  return (
    <>
      <ScheduleNavbar />
      <div className="spacer"></div>
      <Routes>
        <Route path="/" element={<MainView />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
