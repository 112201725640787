import "./InformationContent.css";

const InformationContent = ({
  firstTitle,
  secondTitle,
  infoText,
  imgNumber,
  children,
}) => {
  return (
    <div className="info-content-wrapper">
      <div
        className={`info-content-text-wrapper ${
          imgNumber % 2 === 0 ? "order-two" : "order-one"
        }`}
      >
        <h1 className="info-content-first-title">{firstTitle}</h1>
        <h1 className="info-content-second-title">{secondTitle}</h1>
        <div className="info-content-info-text">{infoText}</div>
        {children}
      </div>
      <img
        src={require(`../../assets/images/showcaseImg${imgNumber}.jpg`)}
        alt="testytesttesttest"
        className={`info-content-img ${
          imgNumber % 2 === 0 ? "order-one" : "order-two"
        }`}
      />
    </div>
  );
};

export default InformationContent;
