import "./RoundBtn.css";

const RoundBtn = ({ text, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="round-btn-wrapper"
    >
      <button className="round-btn-text">{text}</button>
    </a>
  );
};

export default RoundBtn;
